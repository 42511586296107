import React from 'react';
import {
    CreateButton,
    Datagrid,
    DateField,
    downloadCSV,
    ExportButton,
    Filter,
    List,
    Pagination,
    DateInput,
    SelectInput,
    TextField,
    TextInput,
    TopToolbar,
    useListContext,
    FunctionField,
    FilterButton,
    AutocompleteArrayInput
} from 'react-admin';
import TicketOptions from "./TicketOptions";
import jsonExport from 'jsonexport/dist';
import { isinFields } from "../i18n/en";
import ClearFilters from "./ClearFilters";
import { ticketStatus } from "./TicketEnums";
import { priorityRenderer, statusColorRenderer } from "./ColorRenderer";

export const convertToTicketTags = (fields: Record<string, string>): { id: string, name: string }[] => {
    return Object.entries(fields)
        .sort(([, valueA], [, valueB]) => valueA.localeCompare(valueB))
        .map(([key, value]) => ({
            id: value,
            name: value
        }));
};

const ticketTags = convertToTicketTags(isinFields);

const CustomFilters = [
    <TextInput
        label="Search by words"
        source="title"
        alwaysOn
        multiline
        rowsMax={5}
        parse={value => value ? value.toUpperCase() : ''}
        className="custom-filter-field"
    />,
    <AutocompleteArrayInput
        label="Search by status"
        source="status"
        choices={ticketStatus}
        optionText="name"
        optionValue="id"
        fullWidth
        alwaysOn
        className="custom-filter-field"
    />,
    <SelectInput className="custom-filter-field" label="Search by fields" source="tags" choices={ticketTags} alwaysOn />,
    <DateInput className={"custom-filter-field-date"} label="Date Created From" source="date_created_gte" />,
    <DateInput className={"custom-filter-field-date"} label="Date Created To" source="date_created_lte" />,
    <DateInput className={"custom-filter-field-date"} label="Date Updated From" source="date_updated_gte" />,
    <DateInput className={"custom-filter-field-date"} label="Date Updated To" source="date_updated_lte" />
];

const Tickets: React.FC = (props) => {
    const CustomActions = () => {
        const { basePath } = useListContext();

        return (
            <TopToolbar>
                <FilterButton filters={CustomFilters} />
                <CreateButton basePath={basePath} />
                <TicketOptions />
                <ClearFilters />
                <ExportButton basePath={basePath} />
            </TopToolbar>
        );
    };

    const exporter = (data: any[], fetchRelatedRecords: () => void) => {
        data = data.map(record => {
            const { username,assignedToUserId,assignedToCustomerId,ownerUserId,ownerCustomerId,commentToAdd,id, ...rest } = record;  // This line excludes the `username` field
            return {
                ...rest,
                body: record.body ? record.body.replace(/\n/g, " ") : "",
                tags: record.tags ? record.tags.replace(/\n/g, "|") : ""
            };
        });

        jsonExport(data, {
            headers: ['date_created', 'date_updated', 'title', 'body', 'ownernnaName','ownerName','assignedTonnaName','assignedToName','status','priority','currentTicketRole','tags'],
            rename: ['Created','Updated','Title','Description','Group owner','Owner','Assigned group','Assignee','Status','Priority','Role','Tags']

        }, (err: any, csv: string) => {
            downloadCSV(csv, 'tickets export');
        });
    };

    const getStatusName = (id: string): string => {
        const status = ticketStatus.find(status => status.id === id);
        return status ? status.name : id; // Return the name if found, otherwise return the id
    };

    const StatusField: React.FC<{ record?: any }> = ({ record }) => {
        return (
            <span>{getStatusName(record.status)}</span>
        );
    };

    return (
        <>
            <List exporter={exporter} {...props}
                  pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100, 400]}/>}
                  bulkActionButtons={false}
                  actions={<CustomActions/>}
                  filters={<Filter className="custom-filter-form">{CustomFilters}</Filter>}
                  sort={{ field: 'id', order: 'ASC' }}>
                {/*<Datagrid data={tasks} ids={tasks.map(item => item.id)} rowClick="edit">*/}
                <Datagrid rowClick="edit">
                    <TextField label="Ticket Id" source="ticketId"/>
                    <DateField source="date_created" options={{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                    }}/>
                    <DateField source="date_updated" options={{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                    }}/>
                    <TextField label="Title" source="title"/>
                    <TextField label="Description" multiline source="body"/>
                    <TextField label="Owner Group" source="ownernnaName"/>
                    <TextField label="Owner" source="ownerName"/>
                    <TextField label="Assigned Group" source="assignedTonnaName"/>
                    <TextField label="Assigned" source="assignedToName"/>
                    <FunctionField label="Status" source="status" sortable={true} sortBy="status"
                                   render={statusColorRenderer}/>
                    <FunctionField label="Priority" source="priority" sortable={true} sortBy="priority"
                                   render={priorityRenderer}/>
                </Datagrid>
            </List>
        </>
    );
};

export default Tickets;
